import type { SupabaseTable } from "~/types/table.types";

export type LiveCaption = SupabaseTable.Participant & {
  transcript?: SupabaseTable.Transcript["content"];
};

export function useLiveCaption() {
  const { isMobile } = useDevice();

  const maxliveCaptionSlot = useState<number>(() => (isMobile ? 1 : 3));

  const liveCaptions = useState<{ [participantId: string]: LiveCaption }>(
    () => ({}),
  );
  const isSlotFull = computed<boolean>(
    () => Object.keys(liveCaptions.value).length >= maxliveCaptionSlot.value,
  );

  const isChromaKeyMode = useState<boolean>(() => false);
  const isTranscriptVisible = useState<boolean>(() => true);
  const isLiveCaptionVisible = useState<boolean>(() => true);

  function upsertLiveCaption({
    transcript,
    participant,
  }: {
    transcript: SupabaseTable.Transcript;
    participant: SupabaseTable.Participant;
  }) {
    if (liveCaptions.value[participant.id]) {
      liveCaptions.value[participant.id] = {
        ...participant,
        transcript: transcript.content,
      };
    } else {
      if (isSlotFull.value) {
        const participantIds = Object.keys(liveCaptions.value);
        const firstParticipantId = participantIds?.[0];
        if (firstParticipantId) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete liveCaptions.value[firstParticipantId];
        }
      }

      liveCaptions.value[participant.id] = {
        ...participant,
        transcript: transcript.content,
      };
    }
  }

  function clearLiveCaptionTranscript({
    participantId,
  }: {
    participantId: string;
  }) {
    if (liveCaptions.value[participantId]?.transcript) {
      liveCaptions.value[participantId].transcript = undefined;
    }
  }

  return {
    maxliveCaptionSlot,
    isSlotFull,
    liveCaptions,
    upsertLiveCaption,
    clearLiveCaptionTranscript,
    isChromaKeyMode,
    isTranscriptVisible,
    isLiveCaptionVisible,
  };
}

import type { SupabaseTable } from "~/types/table.types";

export function useMeetingManager() {
  const { $http } = useNuxtApp();

  const toast = useToast();
  const { form, organizationId, meeting } = useMeeting();
  const { bot } = useMeetingBot();
  const { transcripts, languagesToSee, downloadLanguages } = useTranscript();
  const { participants } = useParticipant();
  const { trackEvent, TRACKING_EVENT } = useEventTracker();
  const { isZoomApp } = useZoomSdk();
  const {
    user,
    lastUsedSpeakingLanguages,
    onlineMeetingTier,
    ipAddressWhitelist,
  } = useUser();
  const { mainLanguage, speakingLanguages, lastUsedLanguages } =
    useSupportedLanguages();

  const loading = useState<boolean>(() => false);
  const isDataFetching = useState<boolean>(() => false);

  async function initMeeting() {
    try {
      loading.value = true;

      const { meeting } = await $http(`/api/v1/meetings`, {
        method: "POST",
        body: {
          tier: onlineMeetingTier.value,
          userId: user.value?.id,
          organizationId: organizationId.value,
          categories: form.value.categories.map((item) => item.label),
          meetingUrl: form.value.meetingUrl,
          sourceLanguages: speakingLanguages.value,
          targetLanguages: speakingLanguages.value,
          ipAddressWhitelist: ipAddressWhitelist.value,
          glossaryIds: form.value.categories.map((item) => item.id),
        },
      });

      lastUsedSpeakingLanguages.value = speakingLanguages.value;

      if (isZoomApp.value) {
        navigateTo({
          name: ROUTE_NAMES.ZOOM_MEETING_DETAIL,
          params: { meetingId: meeting.id },
        });
      } else {
        navigateTo({
          name: ROUTE_NAMES.MEETING_DETAIL,
          params: { meetingId: meeting.id },
        });
      }

      lastUsedLanguages.value = speakingLanguages.value;

      trackEvent(TRACKING_EVENT.INVITE_BOT, meeting);
    } catch (err) {
      const { data: error } = useResponseError(err);
      switch (error.errorCode) {
        case "ip_address_format_invalid":
          toast.add({
            title: "Failed to Invite Bot",
            description: "Please enter the correct formatted IP",
            icon: "i-lucide-ban",
            color: "rose",
          });
          break;
        default:
          toast.add({
            title: "Failed to Invite Bot",
            description: `Sorry, currently not available. Service will be restored soon. Please try it again a few minutes later.`,
            icon: "i-lucide-ban",
            color: "rose",
            timeout: 0,
          });
          break;
      }

      throw err;
    } finally {
      loading.value = false;
    }
  }

  function fetchMeetingById(id: string) {
    isDataFetching.value = true;

    return $http(`/api/v1/meetings/${id}`, {
      method: "GET",
    })
      .then((resp) => {
        meeting.value = resp.meeting as SupabaseTable.Meeting;

        languagesToSee.value = [resp.meeting.source_language];
        downloadLanguages.value = [resp.meeting.source_language];

        if (!resp.meeting.target_languages.includes(mainLanguage.value)) {
          mainLanguage.value = resp.meeting.target_languages[0];
        }

        if (!bot.value) {
          const meetingBot = resp.meeting
            .MeetingBot?.[0] as SupabaseTable.MeetingBot;

          bot.value = meetingBot;
        }

        transcripts.value = resp.transcript as SupabaseTable.Transcript[];

        resp.meeting?.Participant.forEach((participant) => {
          participants.value[participant.id] =
            participant as SupabaseTable.Participant;
        });

        return resp;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        isDataFetching.value = false;
      });
  }

  async function closeMeetingById(meetingId: string) {
    try {
      loading.value = true;

      const resp = await $http(`/api/v1/meetings/${meetingId}/close`, {
        method: "POST",
      });

      trackEvent(TRACKING_EVENT.MEETING.REMOVE_BOT);

      return resp;
    } catch (err) {
      console.error("failed to close meeting", err);

      const { data } = useResponseError(err);

      if (data.errorCode === "already_closed_meeting") {
        toast.add({
          color: "red",
          title: "The meeting is already closed.",
        });
      } else {
        toast.add({
          color: "red",
          title: "Failed to kick out the bot. Please try it again later.",
        });
      }

      throw err;
    } finally {
      loading.value = false;
    }
  }

  function deleteMeetingById(id: string) {
    return $http(`/api/v1/meetings/${id}`, { method: "DELETE" });
  }

  return {
    deleteMeetingById,
    closeMeetingById,
    loading,
    isDataFetching,
    initMeeting,
    fetchMeetingById,
  };
}

import type { RealtimeChannel } from "@supabase/supabase-js";
import type { SupabaseTable } from "~/types/table.types";

const Constants = {
  SUPABASE: {
    TABLE_NAME: "Participant",
    REALTIME_CHANNEL_NAME: "ParticipantChannel",
  },
} as const;

export function useParticipant() {
  const supabase = useSupabaseClient();
  const { trackEvent, TRACKING_EVENT } = useEventTracker();

  const participants = useState<{
    [id: string]: SupabaseTable.Participant;
  }>(() => ({}));
  const participantSearchQuery = useState<string>(() => "");

  const isBusy = useState<boolean>(() => false);

  function muteParticipant(id: string, isMute: boolean) {
    isBusy.value = true;

    return $fetch(`/api/v1/participants/${id}/mute`, {
      method: "PUT",
      body: { isMute },
    })
      .then(() => {
        trackEvent(TRACKING_EVENT.MEETING.MUTED);
      })
      .finally(() => {
        isBusy.value = false;
      });
  }

  let supabaseChannel: RealtimeChannel | null = null;

  function subscribeParticipantChanges({ meetingId }: { meetingId: string }) {
    supabaseChannel = supabase
      .channel(Constants.SUPABASE.REALTIME_CHANNEL_NAME)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: Constants.SUPABASE.TABLE_NAME,
          filter: `meeting_id=eq.${meetingId}`,
        },
        (payload) => {
          const participant = payload.new as SupabaseTable.Participant;
          participants.value[participant.id] = participant;
        },
      )
      .subscribe();
  }

  function unbsubscribeParticipantChanges() {
    supabaseChannel?.unsubscribe();
  }

  return {
    isBusy,
    participants,
    participantSearchQuery,
    subscribeParticipantChanges,
    unbsubscribeParticipantChanges,
    muteParticipant,
  };
}

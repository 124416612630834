import type { RealtimeChannel } from "@supabase/supabase-js";
import { useLiveCaption } from "./useLiveCaption";
import type { SupabaseTable } from "~/types/table.types";

const Constants = {
  SUPABASE: {
    TABLE_NAME: "Transcript",
    REALTIME_CHANNEL_NAME: "TranscriptChannel",
  },
  ONE_MINUTE: 1000 * 60,
} as const;

export type TranscriptGroups = SupabaseTable.Transcript[][];

export function useTranscript() {
  const toast = useToast();
  const supabase = useSupabaseClient();

  const { trackEvent, TRACKING_EVENT } = useEventTracker();
  const { bot } = useMeetingBot();
  const { participants } = useParticipant();
  const { upsertLiveCaption } = useLiveCaption();
  const { humanReadableLanguages } = useSupportedLanguages();
  const { meeting } = useMeeting();

  const languagesToSee = useState<string[]>(() => []);
  const downloadLanguages = useState<string[]>(() => []);
  const transcripts = useState<SupabaseTable.Transcript[]>(() => []);
  const transcriptGroups = computed<TranscriptGroups>(() => {
    const grouped: TranscriptGroups = [];
    let currentGroup: SupabaseTable.Transcript[] = [];

    for (const transcript of transcripts.value) {
      if (currentGroup.length === 0) {
        currentGroup.push(transcript);
      } else {
        const lastTranscript = currentGroup[currentGroup.length - 1];
        const timeDifference =
          new Date(transcript.created_at).getTime() -
          new Date(lastTranscript.created_at).getTime();

        if (transcript.content.speaker_id) {
          if (
            transcript.content.speaker_id ===
              lastTranscript.content.speaker_id &&
            timeDifference <= Constants.ONE_MINUTE
          ) {
            currentGroup.push(transcript);
          } else {
            grouped.push(currentGroup);
            currentGroup = [transcript];
          }
        } else if (
          transcript.participant_id === lastTranscript.participant_id &&
          timeDifference <= Constants.ONE_MINUTE
        ) {
          currentGroup.push(transcript);
        } else {
          grouped.push(currentGroup);
          currentGroup = [transcript];
        }
      }
    }

    if (currentGroup.length > 0) {
      grouped.push(currentGroup);
    }

    return grouped;
  });

  const documentTitle = ref<string>("");
  const documentContent = ref<string>("");
  function formatTranscriptForExport() {
    const startMeetingTime = meeting.value?.created_at.substring(0, 10);
    const meetingTitle = bot.value?.metadata.meeting_metadata?.title;
    documentTitle.value = `${meetingTitle || startMeetingTime}`;

    documentContent.value = transcripts.value
      .map(
        (chatGroup) =>
          `${chatGroup.username}:  ${
            downloadLanguages.value.length > 1
              ? downloadLanguages.value
                  .map(
                    (item, index) =>
                      `\n[${
                        humanReadableLanguages.value[
                          downloadLanguages.value[index]
                        ]
                      }] ${chatGroup.content.multilingual_text?.[item]}`,
                  )
                  .join("")
              : chatGroup.content.multilingual_text?.[
                  downloadLanguages.value[0]
                ]
          }`,
      )
      .join("\n\n");
  }

  function shareTranscript() {
    formatTranscriptForExport();

    try {
      navigator
        ?.share({
          title: documentTitle.value,
          text: documentTitle.value + "\n\n" + documentContent.value,
        })
        .then(() => {
          trackEvent(TRACKING_EVENT.TRANSCRIPT.SHARED);
        });
    } catch (err) {
      toast.add({
        color: "red",
        icon: "i-lucide-shield-alert",
        title: "Please check your browser setting.",
        description: `If you're using Chrome browser, please enable your browser setting. <br><br>Copy and paste the following URL to your browser search input and enable "Web Share" option.<br><br> chrome://flags/#web-share`,
        timeout: 0,
      });
    }
  }

  function copyTranscript() {
    formatTranscriptForExport();

    window.navigator.clipboard
      .writeText(documentTitle.value + "\n" + documentContent.value)
      .then(() => {
        toast.add({
          title: "The transcript has been saved to the clipboard.",
          icon: "i-lucide-clipboard",
          color: "green",
        });

        trackEvent(TRACKING_EVENT.TRANSCRIPT.COPIED_TO_CLIPBOARD);
      });
  }

  let supabaseChannel: RealtimeChannel | null = null;

  function subscribeTranscriptChanges({ meetingId }: { meetingId: string }) {
    supabaseChannel = supabase
      .channel(Constants.SUPABASE.REALTIME_CHANNEL_NAME)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: Constants.SUPABASE.TABLE_NAME,
          filter: `meeting_id=eq.${meetingId}`,
        },
        (payload) => {
          const transcript = payload.new as SupabaseTable.Transcript;

          const participant = participants.value[transcript.participant_id];

          if (participant) {
            upsertLiveCaption({ participant, transcript });
          }
        },
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: Constants.SUPABASE.TABLE_NAME,
          filter: `meeting_id=eq.${meetingId}`,
        },
        (payload) => {
          const transcript = payload.new as SupabaseTable.Transcript;
          const participant = participants.value[transcript.participant_id];

          if (participant) {
            upsertLiveCaption({ participant, transcript });
          }

          if (!transcript.content?.is_partial) {
            transcripts.value.push(transcript);
          }
        },
      )
      .subscribe();
  }

  function unsubscribeTranscriptChanges() {
    supabaseChannel?.unsubscribe();
  }

  return {
    transcripts,
    transcriptGroups,
    languagesToSee,
    downloadLanguages,
    copyTranscript,
    shareTranscript,
    subscribeTranscriptChanges,
    unsubscribeTranscriptChanges,
  };
}
